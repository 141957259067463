#about {
	opacity: 0;
	position: absolute;
	top: -50%;
	left: 50%;
	transform: translateX(-50%);
	visibility: hidden;
	width: 100%;
	max-width: 1440px;
	box-sizing: border-box;
	transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	height: $_sectionHeightDesktop;
	padding-top: ($_headerHeight + $_headerPaddingDesk);
	padding-bottom: ($_footerHeight + $_headerFooterDesk);

	@include media-breakpoint-down(sm){
		padding-top: ($_headerHeightMob + $_headerPaddingMob);
		padding-bottom: ($_footerHeightMob + $_headerFooterMob);
	}

	&:target {
		opacity: 1;
		top: 0%;
		visibility: visible;
	}
	.about-content {
		position: relative;
		z-index: 20;
		height: 100%;

		.copy{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			p{
				margin: 0;
				padding: 15px;
				font-size: 3.4rem;
    		line-height: 3.9rem;
				font-weight: 500;

				@include media-breakpoint-down(sm){
					padding: 0px 23px;
					font-size: 1.25em;
					line-height: 1.5rem;
				}
				a{
					color: $_lightGrey;
					text-decoration: none;
				}
			}
		}
	}
	.overlay {
		background: $_darkGrey;
		height: 100%;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 10;
	}
}
/*#about {
	left: 50%;
	margin: -250px 0 0 -40%;
	opacity: 0;
	position: absolute;
	top: -50%;
	visibility: hidden;
	width: 80%;
	max-width: 1440px;
	box-shadow: 0 3px 7px rgba(0,0,0,.25);
	box-sizing: border-box;
	transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;

	&:target {
		opacity: 1;
		top: 50%;
		visibility: visible;
	}
	.about-content {
		position: relative;
		z-index: 20;
		border-radius: 5px;

		.copy {
			padding: 15px;
		}
	}
	.overlay {
		background: $_darkGrey;
		height: 100%;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 10;
	}
}*/