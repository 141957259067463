.renderTextContainer{
  border: 2px solid $_red;
  border-radius: 48px;
  height: calc(100% - 70px);
  margin-top: 35px;
  margin-bottom: 35px;

  @include media-breakpoint-down(sm){
    height: calc(100% - 42px);
    margin-top: 21px;
    margin-bottom: 21;
    border-radius: 0px;
    border-left: none;
    border-right: none;
  }
}
#renderText{
  height: calc(100% - 124px);
  overflow: auto;
  margin: 62px 40px;
  font-size: 1.8rem;
  letter-spacing: -.39px;
  line-height: 1.95rem;

  @include media-breakpoint-down(sm){
    height: calc(100% - 100px);
    margin: 50px 20px;
    font-size: .7rem;
    line-height: .7rem;
  }
  p{
    margin: 0 0 32px 0;
    @include media-breakpoint-down(sm){
      margin: 0 0 12px 0;
    }
  }
}