$_sectionHeightDesktop: 100vh;

$_headerHeight: 85px;
$_footerHeight: 70px;

$_headerHeightMob: 45px;
$_footerHeightMob: 35px;

$_headerPaddingDesk: 20px ;
$_headerPaddingMob: 20px;

$_headerFooterDesk: 30px;
$_headerFooterMob: 30px;

$_lightGrey: #cdcdcd;
$_darkGrey: #424242;
$_red: #ff083f;

$_fontSizeOneDesk: 2.7rem;
$_fontSizeOneMob: 1.2rem;

$container-max-widths: (
  xxl: 1540px
) !default;