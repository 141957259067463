@import './../assets/scss/header.scss';
@import './../assets/scss/home.scss';
@import './../assets/scss/textGenerator.scss';
@import './../assets/scss/changeParagraphs.scss';
@import './../assets/scss/renderText.scss';
@import './../assets/scss/copyToClipboard.scss';
@import './../assets/scss/about.scss';
@import './../assets/scss/footer.scss';

.feminipsum-content{
  padding: 0px 150px;
  @include media-breakpoint-down(xxl){
    padding: 0 100px;
  }
  @include media-breakpoint-down(sm){
    padding: 0px;
  }
}